<template>
  <div>
    <div class="flex items-center gap-5 border-b">
      <UiIcon name="search" />
      <FormKit
        v-model="query"
        type="search"
        placeholder="Waar bent u naar op zoek?"
        input-class="$reset w-full text-base py-3 font-quicksand font-semibold focus:outline-0"
      />
    </div>
    <div>
      <div
        v-if="query && products.length > 0"
        class="mt-5 grid grid-cols-1 justify-items-center gap-5 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3"
      >
        <CardsProduct
          v-for="product in products"
          :key="product.slug"
          :product="product"
        />
      </div>
      <div
        v-else-if="query"
        class="mt-5 font-semibold text-gray"
      >
        Sorry, we konden geen resultaten vinden die overeenkomen met uw zoekopdracht.
        Probeer het opnieuw met andere zoektermen of bekijk onze categorieën om producten te vinden die u interesseren.
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useProductsQuery} from '~/graphql/graphql';

const query = ref('');

const {result: productsResult} = useProductsQuery({
  query,
  limit: 20,
}, {
  debounce: 400,
});

const products = computed(() => productsResult.value?.products ?? []);
</script>
