<template>
  <NuxtLink
    v-for="(route, index) in routes"
    :key="index"
    :to="route.to"
    class="nuxt-link link-mobile"
    @click="$emit('toggleNavbar')"
  >
    <UiIcon
      :name="route.icon"
      class="link-icon"
    />
    {{ route.name }}
  </NuxtLink>
</template>

<script lang="ts" setup>
defineEmits<{
  (e: 'toggleNavbar'): void
}>();
const routes = [
  {
    to: '/',
    name: 'Dashboard',
    icon: 'dashboard-icon',
  },
  {
    to: '/account',
    name: 'Account',
    icon: 'cog',
  },
  {
    to: '/webshop',
    name: 'Webshop',
    icon: 'shopping-cart',
  },
  // Staat uit tot nader order
  // {
  //   to: '/educatie',
  //   name: 'Educatie',
  //   icon: 'camera',
  // },
  {
    to: '/academy',
    name: 'Academy',
    icon: 'notebook',
  },
  {
    to: '/downloads',
    name: 'Downloads',
    icon: 'download',
  },
  {
    to: '/before-after',
    name: 'Before/After',
    icon: 'plus',
  },
];
</script>

<style scoped>
.nuxt-link {
  @apply flex w-full items-center gap-4 p-3 text-base font-semibold no-underline transition duration-300 ease-in-out hover:bg-white/10 border-b-2 border-transparent;
}

.link-mobile.router-link-active {
  @apply border-secondary-800 bg-white/5 p-3;
}

.router-link-active .link-icon {
  @apply text-secondary-800;
}
</style>
