<template>
  <div class="flex items-center justify-center gap-10">
    <UiDrawer
      v-model:open="openSearch"
      title="Zoeken"
    >
      <AppSearchDrawer />
    </UiDrawer>
    <UiDrawer
      v-model:open="openNotifications"
      title="Meldingen"
    >
      <AppNotificationsDrawer />
    </UiDrawer>

    <NavbarActionbarButton
      icon="search"
      @click="openSearch = true"
    />
    <div class="flex items-center">
      <NavbarActionbarLink
        icon="question-circle"
        to="/veelgestelde-vragen"
        @click="toggleNavbar"
      >
        <div class="router-link-active router-link-exact-active as-h3 !mb-0 mr-2 !font-bold">
          Help
        </div>
      </NavbarActionbarLink>
    </div>
    <!--
    <NavbarActionbarButton
      icon="bell"
      :amount="4"
      @click="openNotifications = true"
    />
    -->
    <NavbarActionbarLink
      icon="shopping-cart-desktop"
      :amount="cart?.count"
      to="/winkelwagen"
      @click="toggleNavbar"
    />
  </div>
</template>

<script lang="ts" setup>
const openSearch = ref(false);
const openNotifications = ref(false);

const emits = defineEmits<{
  (e: 'toggleNavbar'): void
}>();

const props = defineProps<{
  toggleNavbar: boolean,
}>();

const {cart} = useCart();

function toggleNavbar() {
  if (props.toggleNavbar) {
    emits('toggleNavbar');
  }
}

const route = useRoute();

watch(route, () => {
  openSearch.value = false;
  openNotifications.value = false;
}, {deep: true, immediate: true});
</script>
