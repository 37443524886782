<template>
  <div class="sticky top-0 z-50 flex h-screen w-full justify-center bg-black py-7">
    <div class="grow px-10">
      <div class="flex h-full flex-col justify-between text-white">
        <NuxtLink
          to="/"
          class="flex justify-center"
        >
          <img
            src="@/assets/images/logo.svg"
            alt="Entercare Logo"
          >
        </NuxtLink>

        <div class="divide-y-2 overflow-auto py-1">
          <div>
            <div class="mb-5 text-base">
              Welkom, {{ user.first_name }}
            </div>
            <div class="flex items-center">
              <img
                src="~/assets/images/entercoin.png"
                alt="Entercoin"
                class="h-5 w-6"
              >
              <div class="ml-2 font-quicksand text-[2.6rem] font-bold leading-none">
                {{ user.entercoins }}
              </div>
            </div>
            <div class="mb-4">
              entercoins gespaard
            </div>
            <NuxtLink
              to="/giftshop"
              class="mb-7 flex items-center gap-1 text-xs font-normal underline"
            >
              <UiIcon name="present" /> Bekijk de giftshop
            </NuxtLink>
          </div>

          <div class="flex flex-col gap-2 pt-8">
            <NavbarLinks />
          </div>
        </div>
        <button
          class="flex w-full items-center gap-4 p-3 text-base font-semibold no-underline transition duration-300 ease-in-out hover:bg-white/10"
          @click="signOut"
        >
          <UiIcon name="square-arrow-left" />
          Uitloggen
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const user = useSofieUser();
const {signOut} = useAuth();
</script>
