<template>
  <div class="flex min-h-screen max-lg:flex-col">
    <div class="sticky top-0 z-40 lg:hidden">
      <NavbarMobile />
    </div>

    <div class="max-lg:hidden lg:basis-3/12 xl:basis-2/12">
      <NavbarDesktop />
    </div>

    <div class="flex grow flex-col overflow-y-auto lg:basis-9/12 xl:basis-10/12">
      <div class="flex items-center bg-primary px-3 py-5 font-quicksand lg:sticky lg:top-0 lg:z-40 lg:flex lg:h-28 lg:items-center lg:justify-between lg:px-8 lg:py-0">
        <h1 class="as-h2 mb-0">
          <div class="flex flex-wrap items-center gap-x-3">
            <div
              v-for="(crumb, index) in breadcrumbs"
              :key="index"
              class="breadcrumbs flex items-center gap-x-3"
            >
              <NuxtLink
                class="as-h3 md:as-h2 !mb-0 !font-bold no-underline"
                :to="crumb.to"
              >
                {{ crumb.label }}
              </NuxtLink>
              <span
                v-if="index !== breadcrumbs.length - 1"
                class="as-h3 md:as-h2 !mb-0 !font-bold"
              >></span>
            </div>
          </div>
        </h1>

        <div class="hidden lg:block">
          <NavbarActionbar
            icon-color="text-black-900"
            :toggle-navbar="false"
          />
        </div>
      </div>
      <div class="flex h-[calc(100vh_-_112px)] grow flex-col">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute();
const isGift = toRef(() => useRoute().query.gift);

const breadcrumbs = computed(() => {
  const segments = route.path.split('/').filter(Boolean);

  if (isGift.value) {
    return [
      {label: 'Giftshop', to: '/giftshop'},
      {label: segments[segments.length - 1].replace(/-/g, ' ').replace(/^\w/, c => c.toUpperCase())},
    ];
  }

  const breadcrumbList = [];

  if (segments.length === 0) {
    breadcrumbList.push({label: 'Dashboard', to: '/'});
  } else if (segments.length === 1 && segments[0] === 'account') {
    breadcrumbList.push({label: 'Account', to: '/account'});
    breadcrumbList.push({label: 'Mijn gegevens', to: '/account'});
  } else if (segments.length === 4 && segments[0] === 'webshop') {
    breadcrumbList.push({label: 'Webshop', to: '/webshop'});
    breadcrumbList.push({label: segments[1].replace(/^\w/, c => c.toUpperCase()), to: '/webshop/' + segments[1]});
  } else {
    segments.forEach((segment, index) => {
      breadcrumbList.push({
        to: `/${segments.slice(0, index + 1).join('/')}`,
        label: segment.replace(/-/g, ' ').replace(/^\w/, c => c.toUpperCase()),
      });
    });
  }

  return breadcrumbList;
});
</script>
