<template>
  <div class="font-opensans">
    <div class="flex items-center justify-between text-gray-300">
      <div>{{ totalMessages }} ongelezen berichten</div>
      <button class="text-xs underline">
        Alles markeren als gelezen
      </button>
    </div>
    <div class="mt-5 flex flex-col gap-3">
      <div
        v-for="(notification, index) in notifications"
        :key="index"
      >
        <AppNotificationsDrawerItem :notification="notification" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const totalMessages = computed(() => {
  let total = 0;
  notifications.value.forEach((notification) => {
    total += notification.messages.length;
  });
  return total;
});

const notifications = ref([
  {
    date: 'Vandaag',
    messages: [
      {
        time: '13:31',
        title: 'Accountgegevens',
        message: 'Op de pagina Account kunnen geregistreerde klanten hun persoonlijke gegevens bekijken, zoals hun naam, adres, e-mailadres en meer..',
        link: {to: '/account', title: 'Accountgegevens'},
      },
      {
        time: '09:53',
        title: 'TITEL 2',
        message: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci alias asperiores assumenda debitis deserunt dolorum earum eius eligendi et expedita illum magnam modi molestiae, nihil porro provident similique sint tempore?',
        link: '',
      },
    ],
  },
  {
    date: 'Gisteren',
    messages: [
      {
        time: '19:43',
        title: 'TITEL 3',
        message: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci alias asperiores assumenda debitis deserunt dolorum earum eius eligendi et expedita illum magnam modi molestiae, nihil porro provident similique sint tempore?',
      },
      {
        time: '14:49',
        title: 'TEKST 4',
        message: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci alias asperiores assumenda debitis deserunt dolorum earum eius eligendi et expedita illum magnam modi molestiae, nihil porro provident similique sint tempore?',
        link: '',
      },
    ],
  },
  {
    date: 'Ouder',
    messages: [
      {
        time: '10:04',
        title: 'Educatie pagina',
        message: 'Op de pagina Educatie kunnen geregistreerde klanten videos kijken waarin uitleg wordt gegevens over bepaalde producten',
        link: {to: '/educatie', title: 'Educatie'},
      },
    ],
  },
]);
</script>
