<template>
  <div>
    <Transition
      name="slide"
      mode="in-out"
    >
      <div
        v-if="isActive"
        class="absolute z-50 h-screen w-screen bg-black text-white"
      >
        <div class="flex h-full flex-col justify-between">
          <div class="flex h-20 min-h-[5rem] w-full items-center justify-between">
            <div class="btn group mx-3 h-min border-white p-4 opacity-0 hover:bg-white hover:text-black-900">
              <UiIcon
                name="times"
                class="text-white transition duration-300 group-hover:text-black-900"
              />
            </div>
            <div class="justify-center text-center text-lg">
              Welkom, {{ user.first_name }}
            </div>
            <button
              class="btn group mx-3 h-min border-white p-4 hover:bg-white hover:text-black-900"
              @click="toggleNavbar"
            >
              <UiIcon
                name="times"
                class="text-white group-hover:text-black"
              />
            </button>
          </div>

          <div class="divide-y-2 overflow-auto overscroll-contain px-5 py-1">
            <div class="mb-10 flex items-center justify-center gap-4">
              <img
                src="~/assets/images/entercoin.png"
                alt="Entercoin"
                class="h-5 w-6"
              >
              <div class="font-quicksand text-[2.6rem] font-bold leading-none">
                {{ user.entercoins }}
              </div>
              <div class="flex flex-col">
                <div class="text-lg">
                  Gespaarde punten
                </div>
                <NuxtLink
                  to="/giftshop"
                  class="font-normal underline"
                  @click="toggleNavbar"
                >
                  Bekijk de giftshop
                </NuxtLink>
              </div>
            </div>

            <div class="p-7 text-white">
              <NavbarActionbar
                icon-color="text-white"
                :toggle-navbar="true"
                @toggle-navbar="toggleNavbar"
              />
            </div>

            <div class="px-20 py-5 text-white">
              <div class="mx-auto flex max-w-xs flex-col items-center justify-center gap-2">
                <NavbarLinks @toggle-navbar="toggleNavbar" />
              </div>
            </div>
          </div>

          <div class="px-5">
            <div class="px-20 py-5 text-white">
              <div class="mx-auto flex max-w-xs flex-col items-center justify-center gap-2">
                <button
                  class="flex w-full items-center gap-4 p-3 text-base font-semibold no-underline transition duration-300 ease-in-out hover:bg-white/10"
                  @click="signOut"
                >
                  <UiIcon name="square-arrow-left" /> Uitloggen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <div
      class="top-0 flex h-20 w-full items-center justify-between bg-black text-white"
    >
      <div class="mx-3 flex basis-1/3 items-center">
        <NuxtLink
          to="/"
          class=""
        >
          <img
            class="h-6"
            src="@/assets/images/logo-small.svg"
            alt="Entercare Logo"
          >
        </NuxtLink>
      </div>
      <div class="flex basis-1/3 justify-center">
        <PortalTarget name="mobile-filters" />
      </div>
      <div class="flex basis-1/3 justify-end">
        <button
          class="btn group mx-3 h-min w-min border-white p-4 hover:bg-white hover:text-black-900"
          @click="toggleNavbar"
        >
          <UiIcon
            name="hamburger"
            class="transition duration-300 group-hover:text-black-900"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const user = useSofieUser();

const isActive = ref(false);

function toggleNavbar() {
  isActive.value = !isActive.value;
}

const {signOut} = useAuth();
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  @apply transition-all ease-in-out duration-500;
}

.slide-leave-to,
.slide-enter-from,
.slide-leave {
  @apply -translate-y-full;
}

.slide-enter-to {
  @apply translate-y-0;
}
</style>
