<template>
  <button
    class="h-12 w-full cursor-pointer bg-gray-100 px-2"
    @click="toggleCollapse()"
  >
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-3">
        <div class="font-quicksand text-base font-semibold">
          {{ notification.date }}
        </div>
        <div class="flex items-center justify-center bg-primary p-1 text-xs">
          {{ notification.messages.length }} ongelezen
        </div>
      </div>
      <div class="rounded-full p-2 hover:bg-black/10">
        <UiIcon :name="collapsed ? 'plus' : 'minus'" />
      </div>
    </div>
  </button>
  <div
    class="overflow-hidden"
    :class="{'h-0': collapsed}"
  >
    <div
      v-for="(message, index) in notification.messages"
      :key="index"
      class="border-b-2 border-gray-100 py-2"
    >
      <div class="text-xs text-gray">
        {{ message.time }}
      </div>
      <div class="mb-1 font-semibold">
        {{ message.title }}
      </div>
      <div class="text-gray">
        {{ message.message }}
      </div>
      <NuxtLink
        v-if="message.link"
        :to="message.link.to"
        class="btn btn-secondary mt-2"
      >
        {{ message.link.title }}
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  notification: {
    date: string,
    messages: [
      {
        time: string,
        title: string,
        message: string,
        link: {to: string, title: string},
      },
    ],
  }
}>();

const collapsed = ref(true);

function toggleCollapse() {
  collapsed.value = !collapsed.value;
}
</script>
